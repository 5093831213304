// Welcome to Code in Framer
// Get Started: https://www.framer.com/developers

import { addPropertyControls, ControlType } from "framer"

/**
 * These annotations control how your component sizes
 * Learn more: https://www.framer.com/developers/#code-components-auto-sizing
 *
 * @framerSupportedLayoutWidth any
 * @framerSupportedLayoutHeight any
 */
export default function Formless(props) {
    // This is a React component containing an Example component
    // - Replace <Example /> with your own code
    // - Find inspiration: https://www.framer.com/developers/

    return (
        <>
            <iframe
                src={props.src}
                className="formless-embed"
                width="100%"
                height="600px"
                loading="lazy"
                allow="microphone"
                style={containerStyle}
            ></iframe>

            <script src="https://embed.formless.ai/embed.js" async></script>
        </>
    )
}

Formless.defaultProps = {
    src: "https://formless.ai/c/CX6yGjkgFY1M",
}

addPropertyControls(Formless, {
    src: { type: ControlType.String, title: "Formless URL" },
})

// Styles are written in object syntax
// Learn more: https://reactjs.org/docs/dom-elements.html#style
const containerStyle = {
    border: "0",
    display: "block",
}
